import { AppDomain } from 'types/enums/AppDomain';
import { AppEnv } from 'types/enums/AppEnv';

import {
  getDefaultSiteApiUrl,
  getEnvByHostname,
  getSiteApiUrl,
  getSiteConfigByHostname,
  getSiteWsUrl,
  getTranslationsBaseUrl,
} from './utils';

export class AppConfig {
  env: AppEnv;

  id: number;

  groupId: number;

  domain: AppDomain;

  apiUrl: string;

  wsUrl: string;

  originForInitialId: string;

  originForTranslations: string;

  gtmId: string;

  tiktokId: string;

  googleAuthClientId: string;

  supportEmail: string;

  supportPhone: string;

  sitename: string;

  reCaptcha: string;

  constructor(hostname: string) {
    const env = getEnvByHostname(hostname);
    const siteConfig = getSiteConfigByHostname(hostname);
    const siteId = siteConfig.id;
    const siteGroupId = siteConfig.groupId;
    const siteDomain = siteConfig.domain;
    const siteName =
      siteConfig.sitename || siteDomain.replace(/\.(club|com|me|net)$/, '');
    const siteSupportEmail = `support@${siteDomain}`;
    const siteSupportPhone = siteConfig.supportPhone || '';
    const gtm = (siteConfig.gtm && siteConfig.gtm?.[env]) || '';
    const googleAuth =
      (siteConfig.googleAuthClientId && siteConfig.googleAuthClientId?.[env]) ||
      '';
    const tiktokId = siteConfig.tiktokId || '';
    const reCaptcha = siteConfig?.reCaptcha?.[env] || '';

    this.env = env;
    this.id = siteId;
    this.groupId = siteGroupId;
    this.apiUrl = getSiteApiUrl(hostname);
    this.wsUrl = getSiteWsUrl(hostname);
    this.originForInitialId = getDefaultSiteApiUrl(hostname);
    this.originForTranslations = getTranslationsBaseUrl(hostname);
    this.domain = siteDomain;
    this.gtmId = gtm;
    this.googleAuthClientId = googleAuth;
    this.reCaptcha = reCaptcha;
    this.tiktokId = tiktokId;
    this.supportEmail = siteSupportEmail;
    this.sitename = siteName;
    this.supportPhone = siteSupportPhone;
  }
}
